import React, { useEffect, useCallback, useState } from "react";
import "./Carousel.css";

const carouselControlClass = "carousel-controls";
const carouselSlideClass = "slide";
const targetAttr = "data-slide";
const initializedClass = " is-initialized";
const activeClass = " is-active";

const CarouselItem = ({ item, index, activeIndex }) => {
  const isActive = index === activeIndex;
  const className = `${carouselSlideClass} ${isActive ? activeClass : ""}`;

  return (
    <li className={className} tabIndex={-1} aria-hidden={!isActive}>
      <img src={item.img} alt={item.alt} />
    </li>
  );
};

const CarouselButton = ({ index, activeIndex, onClick }) => {
  const isActive = index === activeIndex;

  return (
    <li>
      <button
        data-slide={index}
        type="button"
        className={`carousel-link ${isActive ? activeClass : ""}`}
        onClick={onClick}
      >
        Show image {index}
      </button>
    </li>
  );
};

const Carousel = ({ items }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const onButtonAction = useCallback((event) => {
    var targetButton = event.target,
      targetIndex = targetButton.getAttribute(targetAttr);

    if (targetIndex && !isNaN(targetIndex * 1)) {
      setActiveIndex(targetIndex * 1);
    }
  }, []);

  const carouselClass = isInitialized
    ? `carousel ${initializedClass}`
    : "carousel";

  return (
    <section className={carouselClass} aria-labelledby="carouselheading">
      <h3 id="carouselheading" className="visuallyhidden">
        Images
      </h3>
      <ul className="carousel-slides">
        {items.map((item, index) => (
          <CarouselItem
            key={item.key}
            item={item}
            index={index}
            activeIndex={activeIndex}
          />
        ))}
      </ul>
      <ul className={carouselControlClass}>
        {items.map((item, index) => (
          <CarouselButton
            key={item.key}
            index={index}
            activeIndex={activeIndex}
            onClick={onButtonAction}
          />
        ))}
      </ul>
    </section>
  );
};

export default Carousel;
