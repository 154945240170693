import React from "react";

import Layout from "../components/layout";
import Services from "../components/services";
import LooseChange from "../components/loose-change";
import LooseFX from "../components/loose-fx";
import WhereIsTheFootball from "../components/where-is-the-football/WhereIsTheFootball";

const IndexPage = () => (
  <Layout>
    <LooseChange />
    <WhereIsTheFootball />
    <LooseFX />
    <Services />
  </Layout>
);

export default IndexPage;
