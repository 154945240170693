import React from "react";
import Carousel from "../carousel";

import displayClipped from "./imgs/display-clipped.png";
import needsClipped from "./imgs/needs-clipped.png";
import productClipped from "./imgs/product-clipped.png";

const URL = "https://loosefx.daftapeth.co.uk";
const items = [
  {
    key: "one",
    img: displayClipped,
    alt: "See both the aggregated values and the average rate of cover.",
  },
  {
    key: "two",
    img: needsClipped,
    alt:
      "Add your currency requirements so you can see how your FX deliveries align with invoices you have to pay",
  },
  {
    key: "three",
    img: productClipped,
    alt: "Define the behavior of your products",
  },
];

const LooseFx = () => (
  <div
    className="row feature-single angled-background"
    itemScope
    itemType="http://schema.org/Product"
  >
    <div className="cell cell-text">
      <div className="cell-description">
        <h2 id="anchor-loosefx" itemProp="name" className="cell-text__heading">
          LooseFx
        </h2>
        <div itemProp="description">
          <p>
            In corporate Foreign Exchange customers can choose to hedge their
            currency requirements against rate moves or aim for out-performance
            of the market.
          </p>
          <p>
            LooseFX aims to give customers a better view of their FX position
            through scenario analysis and invoice planning.
          </p>
        </div>
        <p>
          <a href={URL} itemProp="url">
            Access LooseFx
          </a>
        </p>
      </div>
    </div>
    <div className="cell">
      <Carousel items={items} />
    </div>
  </div>
);

export default LooseFx;
