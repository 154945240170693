import React from "react";

import pitchImg from "./imgs/pitch.jpeg";

const URL = "/where-is-the-football";

const WhereIsTheFootball = () => (
  <div className="row feature-single">
    <div className="cell">
      <img src={pitchImg} alt="" className="angled-image" />
    </div>
    <div className="cell cell-text">
      <div className="cell-description">
        <h2 id="anchor-witf" className="cell-text__heading">
          Where is the Football?
        </h2>
        <div>
          <p>
            Combining Football fixture data from the public internet with the
            visual design from the 2014 Brazil world cup "Where is the
            Football?" conviently shows upcoming games and the channels they'll
            be shown on.
          </p>
        </div>
        <p>
          <a href={URL}>Access "Where is the Football?"</a>
        </p>
      </div>
    </div>
  </div>
);

export default WhereIsTheFootball;
