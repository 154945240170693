import React from "react";
import "./Footer.css";

const Footer = () => (
  <footer>
    <a href="https://daftApeth.co.uk" itemProp="url">
      &copy; Daft Apeth Industries Ltd
    </a>
  </footer>
);

export default Footer;
