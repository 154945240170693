import React from "react";

import "./services.css";

const Services = () => {
  return (
    <div className="zebra-container">
      <h2>Additional Services</h2>
      <div className="row feature-triple">
        <div className="cell">
          <h3>Security Consultation</h3>
          <p>
            Whether accessing current implementations or delivering security
            conscious design our advice is based upon the oWasp best practices
            and industry experience.
          </p>
        </div>
        <div className="cell">
          <h3>Accessibilty Consultation</h3>
          <p>
            Providing both validation and actions to help you ensure you meet{" "}
            <abbr title="Web Content Accessibility Guidelines">WCAG</abbr>.
            Includes practical suggestions for how to use WAI-Aria and HTML5
            semantics.
          </p>
        </div>
        <div className="cell">
          <h3>Software Packages</h3>
          <ul className="no-bullets">
            <li>
              <a
                href="https://github.com/DaftApethIndustries"
                className="link-tuple"
              >
                Interface Widgets:
                <span>GitHub</span>
              </a>
            </li>
            <li>Encrypted IndexedDB: On Request</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
