import React from "react";
import "./Header.css";

import logo from "../../images/daft-apeth-industries.png";

const Header = () => (
  <div className="header">
    <h1 className="header__heading">
      <img
        src={logo}
        alt="Daft Apeth Industries"
        itemProp="logo"
      />
    </h1>
    <ul className="header-list">
      <li>Web.</li>
      <li>Mobile.</li>
      <li>Delivery focus.</li>
      <li>Team leadership.</li>
      <li>Industry knowledge.</li>
    </ul>
  </div>
);

export default Header;
