import React from "react";
import Carousel from "../carousel";

import loosechange1 from "./gallery/loosechange-1.png";
import loosechange2 from "./gallery/loosechange-2.png";
import loosechange3 from "./gallery/loosechange-3.png";

import "./LooseChange.css";

const URL = "https://loosechange.daftapeth.co.uk";
const items = [
  {
    key: "one",
    img: loosechange1,
    alt: "View your accounts in one place",
  },
  {
    key: "two",
    img: loosechange2,
    alt: "Import from CSV, get a combined view and categorise your spending",
  },
  {
    key: "three",
    img: loosechange3,
    alt:
      "Include your credit card spending and visualise it. Add your own categories",
  },
];

const LooseChange = () => (
  <div
    className="row feature-single angled-background"
    itemScope
    itemType="http://schema.org/Product"
  >
    <div className="cell cell-text">
      <div className="cell-description">
        <h2
          id="anchor-loosechange"
          itemProp="name"
          className="cell-text__heading"
        >
          LooseChange
        </h2>
        <div itemProp="description">
          <p>
            LooseChange is your private way to analyse your finances. Export the
            transactions from your bank account and into the app to categorise
            and compare your outgoings.
          </p>
          <p>
            Your information is stored encrypted on your device, giving you full
            control over its storage and deletion.
          </p>
        </div>
        <p>
          <a href={URL} itemProp="url">
            Access LooseChange
          </a>
        </p>
      </div>
    </div>
    <div className="cell loose-change-carousel">
      <Carousel items={items} />
    </div>
  </div>
);

export default LooseChange;
